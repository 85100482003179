import { useState, useEffect } from 'react'
import { ProductCart, slugify, DistributionCenter } from '@ecommerce/shared'
import { getNavigatableProductsBySkus } from '../utils/algolia'

type Params = {
  distributionCenter?: DistributionCenter
  skus: string[]
  listName?: string
  navigationState?: Record<string, unknown>
  attributes?: Parameters<typeof getNavigatableProductsBySkus>[2]
}

const useAlgoliaFetch = ({
  distributionCenter,
  skus,
  listName,
  navigationState = {},
  attributes = { hitsPerPage: 10000 },
}: Params) => {
  const [products, setProducts] = useState<ProductCart[]>([])
  const [loadingProducts, setLoadingProducts] = useState(false)

  const handleAlgoliaQuery = async () => {
    const skusToFetch = [...new Set(skus ?? [])]

    if (skusToFetch && skusToFetch.length > 0 && !loadingProducts && distributionCenter) {
      setLoadingProducts(true)

      const algoliaSkus = await getNavigatableProductsBySkus(
        slugify(distributionCenter?.commerceLayer.stockLocation.name),
        skusToFetch,
        attributes,
        distributionCenter?.slug,
        listName ?? '',
        navigationState,
      )

      setProducts(algoliaSkus)
      setLoadingProducts(false)
    }
  }

  useEffect(() => {
    if (skus && skus.length > 0) handleAlgoliaQuery()
  }, [skus])

  return { products, loadingProducts }
}

export default useAlgoliaFetch
