import { BlockType } from '../../graphql/contentfulTypes'
import secrets from '../../config/secrets'

type Item = {
  sku: string
}

export function extractBlocksSkus(blocks: any[]) {
  return [
    ...new Set<string>(
      blocks
        ?.filter((block) => block.blockType === BlockType.PRODUCT)
        ?.map((block) => {
          const collection = block?.itemsCollection || block?.items

          return collection?.items && Array.isArray(collection?.items)
            ? collection.items.map((item: Item) => item.sku)
            : collection?.map((item: Item) => item.sku)
        })
        .filter(Boolean)
        .flat() ?? [],
    ),
  ]
}

const isFilteredListBlock = (block: any) => block.blockType === BlockType.FILTERED_LIST

const formatFilterItem = (item: any) => {
  return {
    contentful_id: item.sys.id,
    filters: `${item.itemsCollection?.items.map((ic: any) => ic.optionalFilter)}`,
    facetFilters: [
      ...new Set(
        item?.itemsCollection?.items.map((ic: any) =>
          ic.filtersCollection.items.map((fc: any) => `${ic.attributeToFilter}:${fc.label}`),
        ),
      ),
    ]
      .flat()
      .filter((f: any) => !f.includes('Todos'))
      .filter((f: any) => !f.includes('Todas')),
  }
}

export const extractFilters = (blocks: any[]) => {
  const filteredListItems = blocks?.filter(isFilteredListBlock)
  return filteredListItems.map(formatFilterItem)
}

const generateFilterParam = (isHome: boolean, slugLocation: string, filterObject: any) => {
  const baseFilter = `${isHome || secrets.COUNTRY === 'BO' ? 'hasStock:true AND' : ''} slugLocation:${slugLocation}`
  const filterQuery = filterObject.filters ? `AND ${filterObject.filters}` : ''
  return `${baseFilter} ${filterQuery} AND NOT isDummy:true`
}

export const generateQueries = (filters: any, slugLocation: string, isHome: boolean, hitsPerPage = 15) => {
  return filters?.map((f: any) => {
    return {
      indexName: secrets.ALGOLIA_INDEX_NAME,
      params: {
        filters: generateFilterParam(isHome, slugLocation, f),
        facetFilters: [f.facetFilters],
        hitsPerPage,
      },
      queryId: f.contentful_id,
    }
  })
}
