import styled, { css } from 'styled-components'
import { breakpoints, Button } from '@ecommerce/shared'

export const WrapperRecipe = styled.div<{ backgroundColor?: string }>`
  position: relative;
  height: 700px;
  width: calc(100vw - 32px);

  @media screen and (${breakpoints.tabletLandscape.min}) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: auto;
  }

  @media screen and (${breakpoints.desktop.min}) {
    width: 100vw;
    padding-inline-start: 90px;
    margin-inline: 0;
  }

  ${({ backgroundColor }) => {
    return css`
      background: ${backgroundColor};
    `
  }}
`

export const Content = styled.div`
  position: absolute;
  padding: 0 16px;
  bottom: 0;
  color: ${({ theme }) => theme.colors.bodyText};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @media screen and (${breakpoints.tabletLandscape.min}) {
    position: relative;
    padding: 0 10px;
    margin: 0;
    width: 50%;
    align-items: flex-start;
  }
`

export const ContentTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
`

export const ContentBottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  @media screen and (${breakpoints.tabletLandscape.min}) {
    width: auto;
    flex-direction: row;
    gap: 16px;
  }
`

export const ContainerImage = styled.div`
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 700px;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 39.07%, #000 100%),
      linear-gradient(270deg, rgba(0, 0, 0, 0) 0.35%, #000 109.55%);
  }

  @media screen and (${breakpoints.tabletLandscape.min}) {
    position: relative;
    width: 50%;
    height: 376px;
  }

  @media screen and (${breakpoints.desktop.min}) {
    height: 372px;
  }
`

export const StyledImage = styled.img`
  width: 100%;
  object-fit: contain;
  user-select: none;
  user-drag: none;

  @media screen and (${breakpoints.tabletLandscape.min}) {
    object-fit: cover;
    height: 100%;
  }
`

export const Title = styled.p`
  font-size: 24px;
  line-height: 29px;
  font-weight: bold;
  margin: 0;

  @media screen and (${breakpoints.desktop.min}) {
    font-size: 28px;
    line-height: 34px;
  }
`

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.bodyText};
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 14px;
  line-height: 18px;
  margin: 0;

  @media screen and (${breakpoints.desktop.min}) {
    font-size: 16px;
    line-height: 20px;
  }
`

export const StyledButton = styled(Button)`
  width: 100%;
  padding-inline: 35px;
`
