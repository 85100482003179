import styled from 'styled-components'
import { breakpoints } from '@ecommerce/shared'
import { motion } from 'framer-motion'

import * as React from 'react'

export const BigButton = styled(motion.button)`
  width: 135px;
  height: 40px;
  border-radius: 4px;
  background: #f40002;
  border: none;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  cursor: pointer;
  outline: none;

  @media (${breakpoints.desktop.min}) {
    height: 55px;
    width: 200px;
  }
`

interface StyleWraperProps {
  width?: number
  height?: number
  position?: string
  type: 'circle' | 'square'
  hiddenShadow?: boolean
}

interface StyleImageProps extends StyleWraperProps {
  colorImage?: string
}

const Wrapper = styled.div<StyleWraperProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.width !== 0 ? `${props.width}px` : '100%')};
  height: ${(props) => (props.height !== 0 ? `${props.height}px` : '100%')};
  text-align: center;
  box-shadow: ${(props) => (props.hiddenShadow ? 'none' : '0 4px 8px 0 rgba(56, 96, 165, 0.15)')};
  border-radius: ${(props) => (props.type === 'circle' ? `50%` : 0)};
`

const NewImage = styled.img`
  position: absolute;
  width: 70%;
  height: 70%;
`

interface Props extends StyleImageProps {
  image: string
}

export const ResponsiveImage = (props: Props) => {
  const { image, type = 'square', width = 0, height = 0, position = 'none', hiddenShadow = true } = props
  return (
    <Wrapper position={position} width={width} height={height} hiddenShadow={hiddenShadow} type={type}>
      <NewImage src={image} />
    </Wrapper>
  )
}

interface AnchorStylesProps {
  backgroundImage?: string
  width?: number
  height?: number
  position?: string
}

export const Anchor = styled(motion.button)<AnchorStylesProps>`
  background-image: ${(props) => (props.backgroundImage ? `url(${props.backgroundImage})` : 'none')};
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: ${(props) => (props.width || props.height ? 'block' : 'inline')};
  margin: 0;
  padding: 0;
  color: #000000a6;
  width: ${(props) => (props.width ? `${props.width}px` : '30px')};
  height: ${(props) => (props.height ? `${props.height}px` : '20px')};
  position: ${(props) => (props.position ? props.position : 'none')};

  &:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;
  }
`
