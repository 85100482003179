import { OrListFragment, OrRecipeSlideFragment } from './fragments'
import { BaseBlock, BlockType, OrRecipeSlide } from './contentfulTypes'

export interface QueryRecipeSlideResult {
  orList: {
    blockType: BlockType.RECIPE_SLIDE
    itemsCollection: {
      items: OrRecipeSlide[]
    }
  } & BaseBlock
}

export default `
  query RecipeSlideQuery($listId: String!) {
    orList(id: $listId) {
      ...OrListFragment 
        itemsCollection {
          items {
            ...OrRecipeSlideFragment
          }
        }
      }
    }
  ${OrListFragment}
  ${OrRecipeSlideFragment}
`
