import React from 'react'
import { Market } from '@ecommerce/shared'
import RecipeList from './RecipesList'

type Props = {
  listId: string
  themeId?: string
  className?: string
}

const ClientSideRecipeList = ({ className, listId, themeId }: Props) => {
  return <RecipeList listId={listId} themeId={themeId} className={className} />
}

export default ClientSideRecipeList
