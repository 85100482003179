import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { breakpoints, toCssPrefix, Loading, Button, useDimensions } from '@ecommerce/shared'
import { navigate } from 'gatsby'
import { getBorder } from '../PromoList'
import useQuery from '../../hooks/useQuery'
import SectionTitle from '../Moments/SectionTitle'
import Slider from '../FramerSlider'
import recipeSlideQuery, { QueryRecipeSlideResult } from '../../graphql/recipeSlideQuery'
import {
  ContainerImage,
  Content,
  ContentBottom,
  ContentTop,
  Description,
  StyledButton,
  StyledImage,
  Title,
  WrapperRecipe,
} from './styled'

const { cssPrefix, toPrefix } = toCssPrefix('OrRecipeSlides__')

type Props = {
  listId: string
}

const Wrapper = styled.div<{
  allowTitleLowerCase?: boolean
  customBackground?: string
  centerTitle?: boolean
  titleBorderColor?: string
  titleBorderColorShadowNeon?: string
  titleColor?: string
}>`
  color: ${({ theme }) => theme.colors.bodyText};

  .${cssPrefix} {
    &title {
      color: ${({ titleColor }) => titleColor || 'inherit'};
      text-align: ${({ centerTitle }) => (centerTitle ? 'center' : 'left')};
      ${({ titleBorderColor, titleBorderColorShadowNeon }) => getBorder(titleBorderColor, titleBorderColorShadowNeon)}
      ${({ allowTitleLowerCase }) =>
        !allowTitleLowerCase &&
        css`
          text-transform: uppercase;
        `}
    }

    &loader {
      position: absolute;
      width: 84px;
      height: 84px;
      left: calc(50% - 42px);
      top: calc(50% - 42px);
    }

    &slider {
      background: ${({ theme, customBackground }) => customBackground || theme.colors.background.bodyAlternative};
      margin-top: 32px;
      margin-inline: 16px;
    }

    &slider-inner {
      overflow-y: scroll;
    }

    &slider-bullets {
      margin-block-start: 24px;
      margin-block-end: 16px;
    }

    &slider-nav {
      &.next {
        right: 30px;
      }

      &.prev {
        left: 30px;
      }
    }
  }

  @media screen and (${breakpoints.tabletLandscape.min}) {
    .${cssPrefix} {
      &slider-bullets {
        position: relative;
        margin-block-start: -32px;
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    background: linear-gradient(0deg, ${({ theme }) => theme.colors.background.bodyAlternative} 80%, transparent 0);
    padding-top: 0;

    .${cssPrefix} {
      &slider {
        margin-inline: 0;
      }
      &slider-inner {
        overflow-y: auto;
      }
    }
  }
`
type ImageProps = {
  dimensions: number
  imageMobile: string
  imageTablet: string
  imageDesktop: string
}

const Image = ({ dimensions, imageMobile, imageTablet, imageDesktop }: ImageProps) => {
  const getImage = () => {
    if (!dimensions) {
      return
    }
    if (imageMobile && imageTablet && imageDesktop) {
      if (dimensions <= 768) {
        return imageMobile
      }
      if (dimensions <= 1024 && dimensions > 768) {
        return imageTablet
      }
      if (dimensions > 1024) {
        return imageDesktop
      }
    }
  }

  useEffect(() => {
    getImage()
  }, [dimensions])

  return (
    <ContainerImage>
      <StyledImage src={getImage()} />
    </ContainerImage>
  )
}

const RecipeSlideList = ({ listId }: Props) => {
  const [ContainerRef, ContainerDimensions] = useDimensions<HTMLImageElement>()

  const { data, loading, error } = useQuery<QueryRecipeSlideResult>(recipeSlideQuery, { variables: { listId } })

  const orList = data?.orList
  const recipeCollection = orList?.itemsCollection?.items

  if (error !== undefined || (!recipeCollection?.length && !loading)) return null

  return (
    <Wrapper>
      {orList?.title && <SectionTitle text={orList.title} color={orList.titleColor} {...orList} />}
      {loading ? (
        <Loading className={toPrefix('loader')} />
      ) : (
        <Slider dragDirectionLock useBullets className={toPrefix('slider')} itemsCount={recipeCollection?.length || 0}>
          {recipeCollection?.map((recipe) => {
            return (
              <WrapperRecipe ref={ContainerRef} backgroundColor={recipe?.backgroundColor}>
                <Image
                  dimensions={ContainerDimensions.width || 0}
                  imageMobile={recipe?.imageMobile?.url || ''}
                  imageTablet={recipe?.imageTablet?.url || ''}
                  imageDesktop={recipe?.imageDesktop?.url || ''}
                />
                <Content>
                  <ContentTop>
                    {recipe.title && <Title>{recipe.title}</Title>}
                    {recipe.description && <Description>{recipe.description}</Description>}
                  </ContentTop>
                  <ContentBottom>
                    {recipe.primaryTextButton && (
                      <StyledButton
                        onClick={() => navigate(recipe?.primarySlugButton || '')}
                        type="button"
                        btnType="primary"
                        btnColor="white"
                      >
                        {recipe.primaryTextButton}
                      </StyledButton>
                    )}
                    {recipe.secondaryTextButton && (
                      <StyledButton
                        onClick={() => navigate(recipe?.secondarySlugButton || '')}
                        type="button"
                        btnType="secondary"
                        btnColor="white"
                      >
                        {recipe.secondaryTextButton}
                      </StyledButton>
                    )}
                  </ContentBottom>
                </Content>
              </WrapperRecipe>
            )
          })}
        </Slider>
      )}
    </Wrapper>
  )
}

export default RecipeSlideList
