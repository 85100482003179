import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import {
  breakpoints,
  DistributionCenter,
  getStoredDistributionCenter,
  toCssPrefix,
  useLocation,
} from '@ecommerce/shared'
import { backgroundImageStyles } from '../../utils/styles'
import { Block, BlockType, ClientSideBlock } from '../../graphql/contentfulTypes'
import useQuery from '../../hooks/useQuery'
import Promotions from '../home/Promotions'
import ProductsSlidesList from '../../components/Moments/ProductSlidesList'
import ClientSideRecipeList from '../../components/Recipes/ClientSideRecipeList'
import BrandsSliderWidget from '../../components/BrandsSlider/BrandsSliderWidget'
import FilteredProductList from '../../components/Moments/FilteredProductList'
import { sendSelectItemEvent } from '../../utils/events'
import { generateProductUrl } from '../../config/siteBuild/utils'
import { extractFilters, generateQueries } from '../utils'
import useMultipleQueries from '../home/useMultipleQueries'
import OrRecipeSlide from '../../components/OrRecipeSlide'

type GQLBlock = Pick<
  Block,
  | 'allowTitleLowerCase'
  | 'background'
  | 'centerTitle'
  | 'title'
  | 'contentful_id'
  | 'blockType'
  | 'titleBorderColor'
  | 'titleBorderColorShadowNeon'
  | 'titleColor'
>

type Props = {
  pgPageId: string
  pageBlocks?: GQLBlock[]
  backgroundImage?: string
  themeId?: string
  currentDistributionCenter: DistributionCenter
}

type BlocksQueryResult = {
  pgPage: {
    blocksCollection?: {
      items?: ClientSideBlock[]
    }
  }
}

const blocksQuery = `query PromotionsPage($pgPageId: String!) {
    pgPage(id: $pgPageId) {
      blocksCollection(limit: 20) {
        items {
          background {
            url
          }
          blockType
          title
          titleColor
          titleBorderColor
          titleBorderColorShadowNeon
          allowTitleLowerCase
          centerTitle
          sys {
            id
          }
          itemsCollection(limit: 20) {
            items {
              ... on OrFilteredList {
                attributeToFilter
                optionalFilter
                filtersCollection {
                  items {
                    label
                  }
                }
              }
            } 
          }
        }
      }
    }
  }  
`

const { cssPrefix, toPrefix } = toCssPrefix('LiquorTemplate__')
const Wrapper = styled.div<{ backgroundImage?: string }>`
  min-height: 100vh;
  color: ${({ theme }) => theme.colors.bodyText};
  padding: 32px 0 40px;
  background-color: ${({ theme }) => theme.colors.brand.default};
  ${backgroundImageStyles()};

  .${cssPrefix} {
    &promo-cards {
      text-align: center;
      &-card {
        background-position: center;
      }

      &-title {
        font-size: 30px;
        &::after {
          width: 150px;
          left: calc(50% - 74.5px);
        }
      }

      div[type='promo-large'] {
        &:first-child {
          padding-left: 0;
        }
      }
    }

    &brand-slides {
      &-title {
        font-size: 30px;
        margin-bottom: 32px;
        &::after {
          width: 150px;
          left: calc(50% - 74.5px);
          top: 564px;
        }
      }
    }

    &product-slides {
      &-title {
        font-size: 30px;
        &::after {
          width: 150px;
          left: calc(50% - 74.5px);
          top: 44px;
          height: 5.2px;
        }
      }

      &-detail-button {
        color: ${({ theme }) => theme.colors.bodyText};
        border-color: ${({ theme }) => theme.colors.bodyText};
      }

      &-add-product-button:not(div) {
        background: ${({ theme }) => theme.colors.bodyText};
        color: ${({ theme }) => theme.colors.red};
      }

      &-add-product-button:is(div) {
        background: ${({ theme }) => theme.colors.red};
      }
    }

    &recipes {
      &-title {
        font-size: 30px;
        margin-bottom: 20px;
        &::after {
          width: 150px;
          left: calc(50% - 74.5px);
          top: 68px;
        }
      }
    }

    &filtered-products {
      min-height: 685px;
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &brand-slides {
        &-title {
          &::after {
            top: 514px;
          }
        }
      }
      &filtered-products {
        min-height: 735px;
      }
    }
  }
`

const BLockWrapper = styled.section<{ withPadding?: boolean; withMargin?: boolean }>`
  margin: ${({ withMargin = true }) => (withMargin ? '60px 0' : '0')};
  padding: ${({ withPadding }) => (withPadding ? '0 25px;' : '0')};

  @media screen and (${breakpoints.desktop.min}) {
    padding: ${({ withPadding }) => (withPadding ? '0 90px;' : '0')};
  }
`

const LiquorTemplate = ({ backgroundImage, pgPageId, themeId, pageBlocks, currentDistributionCenter }: Props) => {
  const [blocks, setBlocks] = useState(pageBlocks)

  const storedDistributionCenter = getStoredDistributionCenter() || currentDistributionCenter

  const { data, loading } = useQuery<BlocksQueryResult>(blocksQuery, { variables: { pgPageId } })
  const blocksData = data?.pgPage?.blocksCollection?.items ?? []

  const filters = useMemo(() => extractFilters(blocksData ?? []), [blocksData])

  const queries = useMemo(() => generateQueries(filters, storedDistributionCenter.slug, false), [filters])

  const [results] = useMultipleQueries(queries)

  const { isBolivia } = useLocation()

  const formatClientSideBlocks = (): GQLBlock[] => {
    return blocksData?.map(
      ({
        allowTitleLowerCase,
        background,
        blockType,
        centerTitle,
        title,
        titleBorderColor,
        titleBorderColorShadowNeon,
        titleColor,
        sys,
      }) => ({
        allowTitleLowerCase,
        background,
        blockType,
        title,
        contentful_id: sys.id,
        centerTitle: centerTitle ?? false,
        titleColor,
        titleBorderColor,
        titleBorderColorShadowNeon,
      }),
    )
  }

  useEffect(() => {
    if (blocksData && !loading) {
      setBlocks(formatClientSideBlocks())
    }
  }, [data, loading])

  return (
    <Wrapper className={cssPrefix} backgroundImage={backgroundImage}>
      {blocks?.map((block) => {
        switch (block.blockType) {
          case BlockType.PROMO_LARGE:
            return (
              <BLockWrapper key={block.contentful_id} withMargin={false} withPadding>
                <Promotions
                  isLiqueursLanding
                  themeId={themeId}
                  className={toPrefix('promo-cards')}
                  listId={block.contentful_id}
                />
              </BLockWrapper>
            )

          case BlockType.RECIPE_SLIDE:
            return (
              <BLockWrapper key={block.contentful_id}>
                <OrRecipeSlide key={block.contentful_id} listId={block.contentful_id} />
              </BLockWrapper>
            )

          case BlockType.BRAND: {
            return (
              <BLockWrapper withPadding>
                <BrandsSliderWidget
                  className={toPrefix('brand-slides')}
                  key={block.contentful_id}
                  listId={block.contentful_id}
                />
              </BLockWrapper>
            )
          }

          case BlockType.PRODUCT_SLIDE: {
            return (
              <BLockWrapper key={block.contentful_id}>
                <ProductsSlidesList
                  currentDistributionCenter={currentDistributionCenter}
                  listId={block.contentful_id}
                  themeId={themeId}
                  className={toPrefix('product-slides')}
                />
              </BLockWrapper>
            )
          }

          case BlockType.RECIPE: {
            return (
              <BLockWrapper key={block.contentful_id}>
                <ClientSideRecipeList className={toPrefix('recipes')} themeId={themeId} listId={block.contentful_id} />
              </BLockWrapper>
            )
          }

          case BlockType.FILTERED_LIST: {
            return (
              <BLockWrapper key={block.contentful_id} withMargin={false}>
                <FilteredProductList
                  isHome={false}
                  typeCard="large"
                  className={toPrefix('filtered-products')}
                  themeId={themeId}
                  listId={block.contentful_id}
                  items={
                    block.blockType === 'filtered-list'
                      ? results?.find((result) => result.queryID === block.contentful_id)?.hits
                      : undefined
                  }
                  onCardClick={({ product, listName }) => {
                    sendSelectItemEvent(product, listName, isBolivia())
                    navigate(`/products/${generateProductUrl(product.title, product.skuCode)}`, {
                      state: { themeId, originListName: listName },
                    })
                  }}
                />
              </BLockWrapper>
            )
          }

          default:
            return null
        }
      })}
    </Wrapper>
  )
}

export default LiquorTemplate
