import { useEffect, useState } from 'react'
import { getProductsBySkus, Country, slugify, getStoredDistributionCenter, DistributionCenter } from '@ecommerce/shared'
import useQuery, { Options } from '../../hooks/useQuery'
import { RichTextJson, ContentfulMLVideo, CollapseItem } from '../../graphql/contentfulTypes'
import { QueryPromo } from '../../graphql/promoListQuery'
import secrets from '../../config/secrets'
import { Promo } from '../../components/PromoList/common'
import { DetailViewProduct } from '../../components/ProductDetail/types'

const isBO = secrets.COUNTRY === Country.BOLIVIA

type QueryResult = {
  items: Array<{
    video?: ContentfulMLVideo
    sliderImagesCollection?: { items: Array<{ url: string }> }
    customNetContent?: RichTextJson
    labelInfoText?: RichTextJson
    customLabelSteps?: {
      title?: string
      itemsCollection: {
        items: QueryPromo[]
      }
    }
    collapseItemsCollection?: {
      items: Array<CollapseItem>
    }
    grapeWine?: string
    locationWine?: string
    pairingWine?: string
    temperatureWine?: string
    phWine?: string
    acidityWine?: string
    alcoholWine?: string
    residualSugarWine?: string
    agingWine?: string
    theme?: {
      sys?: {
        id: string
      }
    }
  }>
}

const contentfulDefaultLocation = isBO ? 'LA PAZ' : 'ARICA'

const getProductDetailArgs = (skuCode: string, isBundle: boolean): [string, Options] => [
  `query ProductDetail($sku: String!, $location: String!) {
        ${isBundle ? 'bundleCollection' : 'productCollection'}(where: { sku: $sku, location: $location }, limit: 1) {
          items {
            video {
              videoPoster {
                url
              }
              title
              video {
                url
              }
            }
            sliderImagesCollection {
              items {
                url
              }
            }
            customNetContent {
              json
            }
            labelInfoText {
              json
            }
            customLabelSteps {
              title
              itemsCollection {
                items {
                  ... on MlCardPromo {
                    sys {
                      id
                    }
                    promoTitle
                    promoSubtitle
                    promoDescription
                    titleType
                    image {
                      url
                    }
                  }
                }
              }
            }
            collapseItemsCollection {
              items {
                title
                content {
                  json
                }
              }
            }
            grapeWine
            locationWine
            pairingWine
            temperatureWine
            phWine
            acidityWine
            alcoholWine
            residualSugarWine
            agingWine
            theme {
              sys {
                id
              }
            }
          }
        }
      }
    `,
  {
    variables: {
      sku: `${skuCode}`,
      location: contentfulDefaultLocation,
    },
  },
]

interface UseFetchProductDetailArgs {
  buildTimeProduct: DetailViewProduct
  distributionCenter?: DistributionCenter
}

export const useFetchProductDetail = ({ buildTimeProduct, distributionCenter }: UseFetchProductDetailArgs) => {
  const { data, loading } = useQuery<{ [key in 'bundleCollection' | 'productCollection']: QueryResult }>(
    ...getProductDetailArgs(buildTimeProduct.skuCode, buildTimeProduct.isBundle ?? false),
  )

  const storedDistributionCenter = getStoredDistributionCenter()

  const currentDistributionCenter = distributionCenter || storedDistributionCenter

  const [product, setProduct] = useState(buildTimeProduct)
  const [isError, setIsError] = useState(false)
  const [isAlgoliaFetching, setIsAlgoliaFetching] = useState(true)
  const [isContentfulFetching, setIsContentfulFetching] = useState(true)
  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    setIsFetching(isAlgoliaFetching || isContentfulFetching)
  }, [isAlgoliaFetching, isContentfulFetching])

  useEffect(() => {
    const getAlgoliaPromotionData = async () => {
      const res = await getProductsBySkus({
        slugLocation: slugify(currentDistributionCenter?.commerceLayer.stockLocation.name || ''),
        skus: [buildTimeProduct.skuCode],
      })

      if (res?.length) {
        const sku = res?.[0]
        const promotion = sku?.promotion

        if (res[0].promotion?.discountSkus?.length) {
          const discountedProductRes = await getProductsBySkus({
            slugLocation: slugify(currentDistributionCenter?.commerceLayer.stockLocation.name || ''),
            skus: [res[0].promotion.discountSkus[0]],
          })
          if (discountedProductRes?.length && promotion) promotion.discountedProduct = discountedProductRes?.[0]
        }

        setProduct((prev) => ({ ...prev, ...(sku ?? {}), promotion }))
      }
      setIsAlgoliaFetching(false)
    }

    getAlgoliaPromotionData()
  }, [])

  useEffect(() => {
    if (data && !loading) {
      const result = data[buildTimeProduct.isBundle ? 'bundleCollection' : 'productCollection']?.items?.[0]
      const productVideo = result?.video
      const collapseItemsCollection = result?.collapseItemsCollection
      const sliderImages = result?.sliderImagesCollection?.items
        ? result.sliderImagesCollection.items.map((item) => item.url).filter((url) => url)
        : undefined
      const customNetContent = result?.customNetContent
      const labelInfoText = result?.labelInfoText
      const wineDetail = {
        grapeWine: result?.grapeWine === '' || result?.grapeWine === null ? undefined : result?.grapeWine,
        locationWine: result?.locationWine === '' || result?.locationWine === null ? undefined : result?.locationWine,
        pairingWine: result?.pairingWine === '' || result?.pairingWine === null ? undefined : result?.pairingWine,
        temperatureWine:
          result?.temperatureWine === '' || result?.temperatureWine === null ? undefined : result?.temperatureWine,
        phWine: result?.phWine === '' || result?.phWine === null ? undefined : result?.phWine,
        acidityWine: result?.acidityWine === '' || result?.acidityWine === null ? undefined : result?.acidityWine,
        alcoholWine: result?.alcoholWine === '' || result?.alcoholWine === null ? undefined : result?.alcoholWine,
        residualSugarWine:
          result?.residualSugarWine === '' || result?.residualSugarWine === null
            ? undefined
            : result?.residualSugarWine,
        agingWine: result?.agingWine === '' || result?.agingWine === null ? undefined : result?.agingWine,
      }
      const customLabelStepsTitle = result?.customLabelSteps?.title
      const customLabelStepsItems: Promo[] =
        result?.customLabelSteps?.itemsCollection?.items?.map((promo) => ({
          id: promo?.sys?.id,
          titleType: promo?.titleType,
          title: promo?.promoTitle,
          description: promo?.promoDescription,
          image: promo?.image?.url,
          slug: promo?.actionSlugLong || promo?.promoSlug,
          subtitle: promo?.promoSubtitle,
        })) ?? []

      const theme = result?.theme?.sys?.id

      if (productVideo) {
        setProduct((prev) => ({
          ...prev,
          video: productVideo,
        }))
      }

      if (sliderImages) {
        setProduct((prev) => ({
          ...prev,
          sliderImages,
        }))
      }

      if (collapseItemsCollection) {
        setProduct((prev) => ({
          ...prev,
          collapseItemsCollection: {
            items: collapseItemsCollection.items,
          },
        }))
      }

      if (theme) {
        setProduct((prev) => ({
          ...prev,
          theme,
        }))
      }

      setProduct((prev) => ({
        ...prev,
        customNetContent,
        labelInfoText,
        wineDetail,
        customLabelSteps: { title: customLabelStepsTitle, items: customLabelStepsItems },
      }))
    }
    setIsContentfulFetching(false)
  }, [data, loading])

  return { data, loading, product, setProduct, isError, setIsError, isFetching, setIsFetching }
}
