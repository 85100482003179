import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { useShoppingCart, useAuth } from '@ecommerce/shared'
import { ThemeProvider } from 'styled-components'
import { PgPageProps } from '../types/PgPages'
import withPageTransition from '../components/withPageTransition'
import Layout from '../components/Layout'
import LiquorTemplate from '../templates/liquor'
import useTheme from '../hooks/useTheme'
import { sendPageViewEvent } from '../utils/events'

declare const window: Window & { connectif?: any }

const LiqueursPage = ({
  pageContext: { productCategories, liqueursPage, currentDistributionCenter },
  data,
}: PgPageProps<{ liqueursPage: string }>) => {
  const pageData = data?.allContentfulPgPage?.edges?.[0]?.node
  const pageTheme = pageData?.theme

  const { mergeThemes } = useTheme(pageTheme?.contentful_id)
  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendPageViewEvent({ cart, entityInfo, title: 'Mi Bar' })
  }, [])

  return (
    <ThemeProvider theme={mergeThemes}>
      <Layout
        description={pageData?.description}
        keywords={pageData?.keywords}
        categories={productCategories}
        title={pageData.title}
      >
        <LiquorTemplate
          pgPageId={liqueursPage}
          themeId={pageTheme?.contentful_id}
          backgroundImage={pageTheme?.bodyBackgroundImage?.file?.url}
          pageBlocks={pageData?.blocks}
          currentDistributionCenter={currentDistributionCenter}
        />
      </Layout>
    </ThemeProvider>
  )
}

export default withPageTransition(LiqueursPage)

export const query = graphql`
  query LiqueursPageQuery($liqueursPage: String!) {
    allContentfulPgPage(filter: { contentful_id: { eq: $liqueursPage } }) {
      edges {
        node {
          title
          description
          keywords
          blocks {
            title
            contentful_id
            blockType
          }
          theme {
            contentful_id
            bodyBackground
            bodyBackgroundAlt
            bodyText
            brandDefault
            brandTextDefault
            productViewWrapperBackground
            productViewContainerBackground
            layoutBackground
            buttonBackgroundPrimaryColor
            buttonPrimaryColor
            bodyBackgroundImage {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
